<template>
  <b-sidebar
    id="add-new-malla-sidebar"
    :visible="isAddNewMallaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-malla-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Registrar Nueva Malla
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

         <!-- Full Name -->
         <validation-provider
            #default="validationContext"
            name="Clave"
            rules="required"
          >
            <b-form-group
              label="Clave de la Malla"
              label-for="nombre"
            >
              <b-form-input
                id="clave"
                v-model="mallaData.clave"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre de la Malla"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                v-model="mallaData.nombre"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <!-- <validation-provider
            #default="validationContext"
            name="Descripcion"
            rules="required"
          >
            <b-form-group
              label="Descripcion"
              label-for="descripcion"
              
            >
              <b-form-input
                id="email"
                v-model="mallaData.descripcion"
                placeholder="..."
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
 -->
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Registrar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMallaSidebarActive',
    event: 'update:is-add-new-malla-sidebar-active',
  },
  props: {
    isAddNewMallaSidebarActive: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankData = {
      clave: '',
      nombre: '',
      descripcion: ''
    }

    const mallaData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetmallaData = () => {
      mallaData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      store.dispatch('app-malla/addUser', mallaData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-malla-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmallaData)

    return {
      mallaData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
